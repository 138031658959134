<template>
  <div v-if="(orderData.task_order_number && orderData.task_order_path) || (orderData.acceptance_act_number && orderData.acceptance_act_path)">
    <a @click.prevent="toggleOrderDocumentsOverlay($event, orderData.id)" href="#" draggable="false" class="p-link table-link-icon">
      <i class="ti-printer"></i>
    </a>
    <OverlayPanel :ref="'odb-' + orderData.id" :breakpoints="{'960px': '75vw'}">
      <div class="p-pt-2 p-pb-2">
        <a @contextmenu.prevent @click.prevent="getTaskOrderPdf(orderData.task_order_path)" draggable="false" v-if="orderData.task_order_number && orderData.task_order_path" class="p-link p-d-flex p-ai-center">
          <div class="p-mr-2">Task order: {{ orderData.task_order_number }}</div>
          <img src="assets/images/pdf_sm_icon.gif" alt="pdf_sm" draggable="false"/>
        </a>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
// import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins ],
  name: 'OrderDocumentsButton',
  props: {
    orderData: Object
  },
  methods: {
    async getTaskOrderPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`order/get-task-order-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.showFileNotFoundError()
      }
    },
    toggleOrderDocumentsOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['odb-' + id.toString()].toggle(event);
    },
  }
}
</script>

<style lang="scss" scoped>
.pdf-img-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  //display: flex;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 50px;
  }
}

</style>